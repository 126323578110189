<template>
  <div class="contactUs" v-show="isOut">
    <div class="aft_banner">
      <img :src="$host + banner.litpic" alt="" />
      <!-- <img :src="$host + banner.litpicm" alt="" /> -->
    </div>
    <div class="aft_title p80">
      <div class="box">
        <p>{{ contact_cate.englist_name }}</p>
        <div>{{ contact_cate.typename }}</div>
      </div>
    </div>

    <div class="contactBox">
      <div class="box flexBetween m160">
        <div class="boxLeft">
          <div class="toggle flexStart">
            <p
              v-for="(item, index) in contact_list"
              :key="index"
              :class="{ active: index == current }"
              @click="contact_togg(index)"
            >
              <span>{{ item.title }}</span>
            </p>
          </div>
          <div class="toggleMain">
            <div class="item">
              <div class="content1">
                <div>
                  <p class="fl">
                    <img src="../assets/images/con1.png" alt="" />
                  </p>
                  <a @click="callPhone(contact_info.tel)">
                    {{ contact_info.tel }}
                  </a>
                </div>
                <div>
                  <p class="fl">
                    <img src="../assets/images/con2.png" alt="" />
                  </p>
                  <a
                    @click="
                      addLink(
                        contact_list[current].lag,
                        contact_list[current].lat,
                        contact_info.address
                      )
                    "
                  >
                    {{ contact_info.address }}</a
                  >
                </div>
                <div>
                  <p class="fl">
                    <img src="../assets/images/con3.png" alt="" />
                  </p>
                  <a @click="callEmail(contact_info.email)">
                    {{ contact_info.email }}</a
                  >
                </div>

                <div>
                  <p class="fl">
                    <img src="../assets/images/con4.png" alt="" />
                  </p>
                  {{ contact_info.zode }}
                </div>
              </div>
              <div class="content2 flexStart">
                <div class="con">
                  <div>
                    <p class="fl">
                      <img src="../assets/images/con5.png" alt="" />
                    </p>
                    微信
                  </div>
                  <p>
                    <img :src="$host + contact_info.watch" alt="" />
                  </p>
                </div>
                <div class="con">
                  <div>
                    <p class="fl">
                      <img src="../assets/images/con6.png" alt="" />
                    </p>
                    微博
                  </div>
                  <p>
                    <img :src="$host + contact_info.weibo" alt="" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="boxRight">
          <div class="aMap-wrapper bm-view">
            <el-amap class="amap-box" :zoom="zoom" :center="center">
              <!--坐标点-->
              <el-amap-marker
                v-for="marker in markers"
                :position="marker.position"
                :key="marker.id"
                :events="marker.events"
                :icon="marker.icon"
                :offset="marker.offset"
              ></el-amap-marker>
              <!--信息窗体-->
              <el-amap-info-window
                v-if="window"
                :position="window.position"
                :visible="window.visible"
                :content="window.content"
                :offset="window.offset"
              ></el-amap-info-window>
            </el-amap>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mapIconPath from "../assets/images/mapadd.png";

export default {
  name: "contact",
  components: {},
  data() {
    return {
      isOut: false,
      current: 0,
      isHeader: true,
      show: false,

      mapName: "",
      banner: {},
      contact_cate: {},
      contact_list: [],
      contact_info: {},

      // markers: [],
      //   windows:[],
      //   window:'',
      //   pointMarkerTemp:[],
      //   pointMarker:[],//存放gis站点信息
      //   polyline:{
      //     path:[]
      //   }

      center: [121.311631, 31.192373],
      zoom: 16,

      markers: [],
      windows: [],
      window: "",
      pointMarkerTemp: [],
      pointMarker: [], //存放gis站点信息
      polyline: {
        path: [],
      },
    };
  },
  created() {
    this.$http.get("api.php/Webinfo/contact").then(
      function (res) {
        const contact = JSON.parse(res.data);
        this.banner = contact.banner;
        this.contact_cate = contact.newsList.cate;
        this.contact_list = contact.newsList.list;
        this.contact_info = this.contact_list[this.current];
        this.mapName = this.contact_list[this.current].address;
        // this.center = [
        //   this.contact_list[this.current].lag,
        //   this.contact_list[this.current].lat,
        // ];
        // this.markers[0].position = [
        //   this.contact_list[this.current].lag,
        //   this.contact_list[this.current].lat,
        // ];
        this.isOut = true;
        // console.log(contact);
        this.point();
      },
      function (res) {
        console.log(res.status);
      }
    );
  },
  methods: {
    point() {
      let markers = [];
      let windows = [];
      let that = this;

      this.$http.get("api.php/Webinfo/contact").then(
        function (res) {
          const contact = JSON.parse(res.data);
          (that.center = [
            contact.newsList.list[that.current].lag,
            contact.newsList.list[that.current].lat,
          ]),
            markers.push({
              position: [
                contact.newsList.list[that.current].lag,
                contact.newsList.list[that.current].lat,
              ],
              icon: mapIconPath,
              offset: [-10, -60], //窗体偏移
              events: {
                click() {
                  that.windows.forEach((window) => {
                    window.visible = false; //关闭窗体
                  });
                  that.window = that.windows[0];
                  that.$nextTick(() => {
                    that.window.visible = true; //点击点坐标，出现信息窗体
                  });
                },
              },
            });
          windows.push({
            position: [
              contact.newsList.list[that.current].lag,
              contact.newsList.list[that.current].lat,
            ],
            content:
              "<div>" +
              "地 址： <br>" +
              contact.newsList.list[this.current].address +
              "</div>",
            offset: [10, -25], //窗体偏移
            visible: true, //初始是否显示
          });

          this.markers = markers;
          //生成弹窗
          this.windows = windows;
        },
        function (res) {
          console.log(res.status);
        }
      );
    },
    contact_togg(index) {
      this.current = index;
      this.contact_info = this.contact_list[this.current];
      this.mapName = this.contact_list[this.current].address;
      this.point();
      // console.log(this.center);
      this.$forceUpdate();
    },
    callPhone(phoneNumber) {
      window.location.href = "tel://" + phoneNumber;
    },
    callEmail(callEmail) {
      window.location.href = "mailto:" + callEmail;
    },
    addLink(lng, lat, name) {
      // console.log(lng, lat, name);
      window.open(
        `http://uri.amap.com/marker?position=${lng},${lat}&name=${name}&coordinate=gaode&callnative=1`
      );
    },
  },
};
</script>

<style lang="less" scoped>
.bm-view {
  width: 100%;
  height: 6.8rem;
}

@gilroy_b: "gilroy_bold";
@gilroy_r: "gilroy_regular";
@albb_b: "albb_b";
@albb_r: "albb_r";
@albb_l: "albb_l";
@albb_m: "albb_m";
@color: #97c852;
.contactBox {
  margin-top: 0.64rem;
  padding-bottom: 0.9rem;
  .box {
    .boxLeft {
      width: 40%;
      .toggle {
        padding-top: 0.25rem;
        p {
          font-size: 0.28rem;
          padding-right: 0.75rem;
          border-bottom: 1px solid #ddd;
          span {
            display: inline-block;
            padding-bottom: 0.16rem;
            position: relative;
            top: 1px;
            transition: 0.4s;
            cursor: pointer;
            &:hover {
              color: @color;
            }
          }
          &:last-child {
            padding-right: 0;
          }
        }
        .active {
          font-family: @albb_b;
          span {
            border-bottom: 2px solid @color;
          }
        }
      }
      .toggleMain {
        margin-top: 0.8rem;
        .item {
          .content1 {
            div {
              font-size: 0.26rem;
              color: #333;
              padding: 0.18rem 0;
              p {
                width: 0.56rem;
                img {
                  vertical-align: middle;
                  width: 0.34rem;
                }
              }
            }
          }
          .content2 {
            .con {
              margin-right: 0.48rem;

              & > div {
                font-size: 0.26rem;
                color: #333;
                padding: 0.3rem 0;
                p {
                  width: 0.56rem;
                  img {
                    vertical-align: middle;
                    width: 0.34rem;
                  }
                }
              }
              & > p {
                img {
                  width: 1.35rem;
                }
              }
            }
          }
        }
      }
    }
    .boxRight {
      width: 60%;
    }
  }
}
@media screen and(max-width:768px) {
  .contactBox .box .boxLeft {
    width: 100%;
  }
  .contactBox .box .boxRight {
    width: 100%;
    margin-top: 0.5rem;
  }
  .bm-view {
    height: 4.8rem;
  }
}
</style>
